<template>
    <div>
    </div>
</template>


<script>
    export default {
        name: "Aside",
        data(){
            return{
            }
        },
        created() {
        }
    }
</script>


<style >



</style>