<template>
    <div>
        <!--    头部-->
        <Header />
        <!--    主体-->
        <router-view/>
        <!--    避免底部遮挡-->
        <div>　</div>
        <div>　</div>
        <div>　</div>
        <!--    底部-->
        <van-tabbar v-model="active" route>
            <van-tabbar-item replace to="/index" name="index" icon="home-o">首页</van-tabbar-item>
            <van-tabbar-item replace to="/campus" name="campus" icon="records">校园</van-tabbar-item>
            <van-tabbar-item replace to="/attention" name="attention" icon="todo-list-o">任务</van-tabbar-item>
            <van-tabbar-item replace to="/my" name="setting" icon="contact">我的</van-tabbar-item>
        </van-tabbar>
    </div>
    <Aside />
</template>

<script>
    import Header from "@/components/Header";
    import Aside from "@/components/Aside";



    export default {
        name:"layout",
        components:{
            Header,
            Aside,
        }
    }
</script>

<style scoped>

</style>