<template>
    <div>
        <router-view />
    </div>
</template>

<script>
    export default {
        name:"App",
    }
    window.onload = function() {
        document.addEventListener('touchstart', function(event) {
            if (event.touches.length > 1) {
                event.preventDefault()
            }
        });
        document.addEventListener('gesturestart', function(event) {
            event.preventDefault()
        })
    };
</script>