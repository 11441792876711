import { createRouter, createWebHashHistory } from 'vue-router';
import Layout from '../layout/Layout.vue'
import AdminLayout from "../layout/AdminLayout";

const routes = [
  //前台
  {path: '/login',name: 'login',component: () => import("../views/home/login")},
  {path: '/register',name: 'register',component: () => import("../views/home/register")},
  {path: '/notice',name: 'myNotice',component: () => import("../views/home/notice")},
  {path: '/closePhone',name: 'closePhone',component: () => import("../views/home/closePhone")},
  {path: '/test',name: 'test',component: () => import("../views/home/test")},
  {path: '/myInfo',name: 'myInfo',component: () => import("../views/home/my/myInfo")},
  {path: '/myStuNum',name: 'myStuNum',component: () => import("../views/home/my/myStuNum")},
  {path: '/mySchedule',name: 'mySchedule',component: () => import("../views/home/my/mySchedule")},
  {path: '/collect',name: 'collect',component: () => import("../views/home/my/collect")},
  {path: '/history',name: 'history',component: () => import("../views/home/my/history")},
  {path: '/forum/my',name: 'myForumList',component: () => import("../views/home/forum/myList")},
  {path: '/forum',name: 'forumList',component: () => import("../views/home/forum/list")},
  {path: '/forum/add',name: 'forumAdd',component: () => import("../views/home/forum/add")},
  {path: '/forum/:id',name: 'forumPage',component: () => import("../views/home/forum/page")},
  {path: '/page/:id',name: 'articlePage',component: () => import("../views/home/article/page")},
  {path: '/list',name: 'articleList',component: () => import("../views/home/article/list")},
  {path: '/enword',name: 'enword',component: () => import("../views/home/enword")},
  {path: '/exercise',name: 'exerciseIndex',component: () => import("../views/home/exercise/index")},
  {path: '/exercise/search',name: 'exerciseSearch',component: () => import("../views/home/exercise/search")},
  {path: '/exercise/chapter/:lastID',name: 'exerciseChapter',component: () => import("../views/home/exercise/chapter")},
  {path: '/exercise/type/:lastID',name: 'exerciseType',component: () => import("../views/home/exercise/type")},
  {path: '/exercise/list/:list',name: 'exerciseList',component: () => import("../views/home/exercise/list")},
  {path: '/exercise/question/:type/:checked/:chapter',name: 'exerciseQuestion',component: () => import("../views/home/exercise/question")},
  {path: '/',name: 'Layout',component: Layout, redirect:"/index",
    children:[
      {path: '/index',name: 'index',component: () => import("../views/home/index")},
      {path: '/campus',name: 'campus',component: () => import("../views/home/campus")},
      {path: '/attention',name: 'attention',component: () => import("../views/home/attention")},
      {path: '/my',name: 'my',component: () => import("../views/home/my")},
    ]},
  //后台
  {path: '/adminLogin',name: 'admin',component: () => import("../views/admin/Login")},
  {path: '/admin',name: 'AdminLayout',component: AdminLayout, redirect:"/admin/index",
    children:[
      {path:'/admin/index',name:'adminIndex',component: () => import("../views/admin/index")},
      {path:'/admin/user',name:'user',component: () => import("../views/admin/user")},
      {path:'/admin/pic',name:'pic',component: () => import("../views/admin/pic")},
      {path:'/admin/notice',name:'notice',component: () => import("../views/admin/notice")},
      {path:'/admin/forum',name:'forum',component: () => import("../views/admin/forum")},
      {path:'/admin/article',name:'article',component: () => import("../views/admin/article")},
      {path:'/admin/phone',name:'phone',component: () => import("../views/admin/phone")},
      {path:'/admin/semesters',name:'semesters',component: () => import("../views/admin/semesters")},
      {path:'/admin/applicationGroup',name:'applicationGroup',component: () => import("../views/admin/applicationGroup")},
      {path:'/admin/application',name:'application',component: () => import("../views/admin/application")},
      {path:'/admin/exerciseCourse',name:'exerciseCourse',component: () => import("../views/admin/exercise/course")},
      {path:'/admin/exerciseList',name:'exerciseList',component: () => import("../views/admin/exercise/list")},
      {path: '/admin/:pathMatch(.*)*',name: 'admin404',component: () => import("../views/admin/404")},
    ]},
  {path: '/:pathMatch(.*)*',name: '404',component: () => import("../views/404")},
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export { router };