import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';
import Vant from 'vant'
import 'vant/lib/index.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import 'dayjs/locale/zh-cn'

const app = createApp(App);
app.use(router);
app.mount('#app');
app.use(Vant);
app.use(ElementPlus, {
    locale: zhCn,
});

