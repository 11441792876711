<template>
    <div>
        <el-menu
                style="width: 200px; min-height: calc(100vh - 50px)"
                :default-active="path"
                router
                class="el-menu-vertical-demo">
            <el-menu-item index="index"><el-icon><House /></el-icon>首页</el-menu-item>
            <el-menu-item index="pic"><el-icon><Picture /></el-icon>轮播图</el-menu-item>
            <el-menu-item index="article"><el-icon><MessageBox /></el-icon>文章发布</el-menu-item>
            <el-menu-item index="forum"><el-icon><ChatDotRound /></el-icon>校园论坛</el-menu-item>
            <el-menu-item index="exerciseCourse"><el-icon><ChatDotRound /></el-icon>题库管理</el-menu-item>
            <el-sub-menu index="applicationManage">
                <template #title><el-icon><Setting /></el-icon>应用管理</template>
                <el-menu-item index="applicationGroup"><el-icon><User /></el-icon>应用分组</el-menu-item>
                <el-menu-item index="application"><el-icon><User /></el-icon>应用设置</el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="userManage">
                <template #title><el-icon><Setting /></el-icon>用户管理</template>
                <el-menu-item index="user"><el-icon><User /></el-icon>账号管理</el-menu-item>
                <el-menu-item index="userGroup"><el-icon><User /></el-icon>用户组</el-menu-item>
                <el-menu-item index="student"><el-icon><User /></el-icon>学生信息</el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="systemSetting">
                <template #title><el-icon><Setting /></el-icon>系统设置</template>
                <el-menu-item index="notice"><el-icon><DocumentAdd /></el-icon>系统公告</el-menu-item>
                <el-menu-item index="semesters"><el-icon><CopyDocument /></el-icon>学期设置</el-menu-item>
                <el-menu-item index="department"><el-icon><CopyDocument /></el-icon>部门设置</el-menu-item>
                <el-menu-item index="major"><el-icon><CopyDocument /></el-icon>专业设置</el-menu-item>
                <el-menu-item index="class"><el-icon><CopyDocument /></el-icon>班级设置</el-menu-item>
            </el-sub-menu>
            <el-menu-item index="phone"><el-icon><Iphone /></el-icon>查看预览</el-menu-item>
        </el-menu>
    </div>
</template>

<script>
    import request from "../utils/adminRequest";
    import {MessageBox,Picture,DocumentAdd,Tickets,Suitcase,Postcard,EditPen,ChatDotRound,Star,Filter,
            House, Setting, User, CopyDocument, Connection, Iphone} from '@element-plus/icons-vue';

    export default {
        name: "Aside",
        components: {MessageBox,Picture,DocumentAdd,Tickets,Suitcase,Postcard,EditPen,ChatDotRound,Star,Filter,
                    House, Setting, User, CopyDocument, Connection, Iphone},
        data(){
            return{
                user: {},
                path: this.$route.path,
            }
        },
        created() {
            //用户信息获取语句
            let userStr = sessionStorage.getItem("user") || "{}"
            this.tk = JSON.parse(userStr)
            console.log("this.tk=",this.tk)
            request.post("/user/tokenLogin", this.tk).then(res => {
                console.log(res)
                if (res.code === 200) {
                    this.user = res.user
                }
            })
            //用户信息获取语句结束
        }
    }
</script>


<style >



</style>